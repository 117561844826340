import React, { Component } from 'react';
import AuthService from '../services/AuthService.js';
import handleErrors from '../handleErrors.js';
class EditionBox extends Component {
	constructor (props) {
		super(props);
		this.changeEditionState = this.changeEditionState.bind(this);
		this.state = {edition : this.props.edition};
	}
	changeEditionState(e)
	{
		e.preventDefault();
		var {imageID} = this.props;
		var {edition} = this.state;
		var url = process.env.REACT_APP_IMAGES_BASE_URL + '/image/' + imageID + '/edition/' + edition.edition;
		console.log(url);
		var params = {method:'PUT',
			headers: {'Authorization': 'Bearer '+AuthService.getApiKey()}};
		switch (edition.status) {
			case 'FREE':
				this.props.showDialogCB('Do you want to reserve edition '+edition.edition, {
					yes: () => {
						fetch(url + '/reserve', params)
							.then(handleErrors)
							.then(response => response.json())
							.then(data => {
								this.setState({edition:data});
								this.props.hideDialogCB();
							})
							.catch(err => {
								console.log(err);
								this.props.hideDialogCB();
							});
					},
					no: () => {
						this.props.hideDialogCB();
					}
				}
				);
				break;
			case 'RESERVED':
				this.props.showDialogCB('Do you want to sell or release edition '+edition.edition, {
					yes: () => {
					fetch(url + '/sell', params)
						.then(handleErrors)
						.then(response => response.json())
						.then(data => {
							this.setState({edition:data});
							this.props.hideDialogCB();
						})
						.catch(err => {
							console.log(err);
							this.props.hideDialogCB();
						});
	},
					no: () => {
						params.method = 'DELETE'
						fetch(url + '/reserve', params)
							.then(handleErrors)
							.then(response => response.json())
							.then(data => {
								this.setState({edition:data});
								this.props.hideDialogCB();
							})
							.catch(err => {
								console.log(err);
								this.props.hideDialogCB();
							});

				}
				},
					{yes: 'Sell', no: 'Release'}
				);
				break;
			case 'SOLD':
				params.method = 'DELETE';
				this.props.showDialogCB('Do you want to reverse the sale on edition '+edition.edition, {
					yes: () => {
				fetch(url + '/sell', params)
						.then(handleErrors)
						.then(response => response.json())
						.then(data => {
							this.setState({edition:data});
							this.props.hideDialogCB();
						})
						.catch(err => {
							console.log(err);
							this.props.hideDialogCB();
						});
					},
					no: () =>
					{
						this.props.hideDialogCB();
					}
				}
				);
				break;
			default:
				break;
		}
	}
	render()
	{
		if (this.state.edition.can_edit) {
		return (
			<div className="column has-text-centered is-size-7 is-1" >
			<a href={'image/' + this.props.imageID + '/edition/' + this.state.edition.edition} onClick={this.changeEditionState}  >		
			<div className={"box edition-box has-background-"+this.state.edition.status.toLowerCase()}>
			{this.state.edition.edition}
			</div>
			</a>
			</div>
		);
		} else {
		return (
			<div className="column has-text-centered is-size-7 is-1" >
			<a className="disabled" href="." onClick = {(e) => e.preventDefault()} >		
			<div className={"box edition-box disabled has-background-"+this.state.edition.status.toLowerCase()}>
			{this.state.edition.edition}
			</div>
			</a>
			</div>
		);
		}
	}
}

export default EditionBox;
