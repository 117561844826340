import React, { Component } from 'react';
import queryString from 'query-string';

import AuthService from '../services/AuthService.js';
import ImageRow from './image-row.js';
import ImageDetail from './image-detail.js';
import EditionDetail from './edition-detail.js';
import Modal from './Modal.js';
class AllImages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			images: [],
			filtered: [],
			showEditions: false,
			series: '',
			showDialog: false,
			dialogText: '',
			DialogCB: () => {},
			buttonText: {yes:'Yes', no:'No'},
		};
		this.modifyFilter = this.modifyFilter.bind(this);
		this.showDialog = this.showDialog.bind(this);
	}

	showDialog(text, DialogCB, buttonText = {yes:'Yes', no:'No'})
	{
		this.setState({showDialog:true, dialogText: text, DialogCB: DialogCB, buttonText: buttonText});
	}
	hideDialog()
	{
		this.setState({showDialog:false, dialogText: ''});
	}
	componentWillMount() {
		const srchTerms = queryString.parse(this.props.location.search);
 		let query = '';
		if ('series' in srchTerms)
		{
			query = process.env.REACT_APP_IMAGES_BASE_URL+'/images?series='+srchTerms.series;
			this.setState({series: srchTerms.series});
		} else {
			query = process.env.REACT_APP_IMAGES_BASE_URL + '/images';
			this.setState({series: ''});
		}
		fetch(query, {headers: {'Authorization': 'Bearer '+AuthService.getApiKey()}})
		.then(response => response.json())
		.then(data =>
			{this.setState({images:data});this.setState({filtered:data})}
		).catch(err => {localStorage.removeItem('api_key');console.log(err)});
	}
  modifyFilter(e) {
    let newList = [];

    if (e.target.value !== "")
    {
      newList = this.state.images.filter(filterList(e.target.value));
			this.setState({showEditions: true});
    } else {
      newList = this.state.images;
			this.setState({showEditions: false});
    }
    this.setState({filtered: newList});
  }
	render() {
		return (
			<React.Fragment>
			<h2 className="title">{this.state.series}</h2>
			<Modal content={this.state.dialogText} active={this.state.showDialog} closeDialogCB={this.hideDialog.bind(this)} DialogCB = {this.state.DialogCB} buttonText = {this.state.buttonText} />
			<input type="text" className="input box" onChange={this.modifyFilter} placeholder="Search..." />
			{this.state.filtered.map(image => <ImageRow key={image.id} image={image} showEditions={this.state.series !== '' || this.state.showEditions} showDialogCB = {this.showDialog.bind(this)} hideDialogCB = {this.hideDialog.bind(this)} /> )}
			</React.Fragment>
		)

	}
}

function filterList(filter) {
    return function(image) {
        const title = image.title.toLowerCase();
			  const series = image.series.toLowerCase();
        return (title.includes(filter) || series.includes(filter));
    }
}
export {AllImages, ImageDetail, EditionDetail};
