import React, {Component} from 'react';

class Modal extends Component {
	// eslint-disable-next-line
	constructor(props) {
		super(props);
	}
	render()
	{
		return (
			<div className={"modal" + (this.props.active?" is-active":"")}>
				<div className="modal-background" onClick = {this.props.closeDialogCB}></div>
				<div className="modal-content">
					<div className="box is-paddingless">
						<div className="message">
							<div className="message-header">
								Header
							</div>
							<div className="message-body">
								<div>
								{this.props.content}
								</div>
								<div className="buttons">
									<button className="button" onClick = {this.props.DialogCB.yes}>{this.props.buttonText.yes}</button>
									<button className="button" onClick = {this.props.DialogCB.no}>{this.props.buttonText.no}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<button className="modal-close is-large" onClick = {this.props.closeDialogCB} aria-label="close"></button>
			</div>
		);
	}
}

export default Modal;
