
import handleErrors from '../handleErrors.js';

class AuthService {

	static Logout(cb)
	{
		localStorage.removeItem('api_key');
		localStorage.removeItem('user');
		setTimeout(cb,100);
	}
	static getAuthHeaders()
	{
		return {'Authorization': 'Bearer '+AuthService.getApiKey()}
	}
	static IsLoggedIn()
	{
		return (AuthService.getApiKey() != null);
	}
	static getCurrentUser()
	{
		var user = localStorage.getItem('user');
		try {
			user = user && JSON.parse(user);
			return user;
		}
		catch(err) {
			console.log(err);
		}
	}
	static getUserName()
	{
		var user = localStorage.getItem('user');
		try {
		user = user && JSON.parse(user);
		var username = user && user.username;
		return username;
		}
		catch(err) {
			console.log(err);
		}
	}
	static getApiKey()
	{
		var api_key = localStorage.getItem('api_key');
		return api_key;
	}
	static Login(userName, password, successCallback, failureCallback)
	{
		var credentials;
		if (userName.indexOf('@') === -1)
		{
			credentials = {userName: userName, password: password};
		}
		else
		{
			credentials = {email: userName, password: password};
		}
		var url = process.env.REACT_APP_IMAGES_BASE_URL + '/login';
		var params = {method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(credentials)
		};
		fetch(url, params)
			.then(handleErrors)
			.then(response => {
				return response.json();
			})
			.then(
				data => {
					localStorage.setItem('api_key', data.api_key);
					localStorage.setItem('user', JSON.stringify(data.user));
					setTimeout(successCallback,100);
				}
			)
			.catch(
				err => {
					setTimeout(failureCallback, 100, err);
				}
			);
	}
}

export default AuthService;
