import React, {Component} from 'react';
import AuthService from '../services/AuthService.js';
import {Redirect} from 'react-router-dom';
class Login extends Component
{
	constructor(props) {
		super(props);
		this.state = {userName: '',
			password: '',
		redirectToReferrer: false,
		errors: []};
		this.login = this.login.bind(this);
		this.clearErrors = this.clearErrors.bind(this);
		this.handleUserNameChange = this.handleUserNameChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
	}
	parseResponse(response)
	{
		response.json().then(data => {console.log(data.api_key)});
	}
	clearErrors(e)
	{
		this.setState({errors: []});
	}
	validate(email, password)
	{
		const errors = [];
		if (email.length ===  0) {
			errors.push("Email can't be blank");
		}
		if (password.length ===  0) {
			errors.push("Password can't be blank");
		}
		return errors;
	}
	storeApiKey(data)
	{
		console.log('Storing '+data);
		localStorage.setItem('api_key', data.api_key);
	}
	login(e)
	{
		e.preventDefault();
		//validate inputs
		const { userName, password } = this.state;

		const errors = this.validate(userName, password);
		if (errors.length > 0) {
			this.setState({errors});
			return;
		}
		AuthService.Login(
			this.state.userName, 
			this.state.password, 
			() =>{this.setState({redirectToReferrer: true})},
			(err) => {
				this.setState({
					errors: [err.message]
				});
			}
	);
	}
	handlePasswordChange(e) {
		this.setState({password: e.target.value});
	}
	handleUserNameChange(e) {
		this.setState({userName: e.target.value});
	}
	render() {
		const { from } = this.props.location.state || { from: { pathname: '/' } }
		const { redirectToReferrer } = this.state
		const { errors } = this.state;

		if (redirectToReferrer)
		{
			return (
				<Redirect to={from} />
			);
		} else {
			return (
			<section className="hero is-fullheight">
				<div className="hero-body">
					<div className="container has-text-centered">
						<div className="column is-4 is-offset-4">
							<h3 className="title has-text-grey">Login</h3>
							<p className="subtitle has-text-grey">Please login to proceed.</p>
							<div className="box login">
								<figure className="avatar">
									<img alt="" src={process.env.PUBLIC_URL + '/images/vintagedolls/Camellia.jpg'} />
								</figure>
				{errors.length > 0 && (
								<div className="notification is-warning">
									<button className="delete" onClick={this.clearErrors}></button>
				{errors.map(error => (
          <p key={error}>Error: {error}</p>
        ))}
								</div>
				)}
								<form>
									<div className="field">
										<div className="control">
											<input className="input is-large" type="email" placeholder="Your Email" autoFocus="" value = {this.state.userName} onChange={this.handleUserNameChange}/>
										</div>
									</div>

									<div className="field">
										<div className="control">
											<input className="input is-large" type="password" placeholder="Your Password" value = {this.state.password} onChange={this.handlePasswordChange}/>
										</div>
									</div>
									<button className="button is-block is-info is-large is-fullwidth" onClick={this.login}>Login</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
		}
	}
}


export default Login
