import React, { Component } from 'react';
import AuthService from '../services/AuthService.js';

class EditionRow extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {locationChanged: false,
			notesChanged: false};
	}
	handleSubmit(event) {
		event.preventDefault();
		var edition = this.props.edition;

		var url = process.env.REACT_APP_IMAGES_BASE_URL + '/image/' + edition.imageID + '/edition/' + edition.edition;
		var params = {method: 'PATCH',
			headers: { 'Content-Type': 'application/json',
			'Authorization': 'Bearer '+AuthService.getApiKey()},
			body: JSON.stringify(edition)
		};
			fetch(url, params).then(res => {return res;}).catch(err => {console.log(err)});
		this.setState({locationChanged: false,
			notesChanged: false});
	}
	render ()
	{
		var locationChangeHandler = this.props.changeHandler(this.props.edition.edition, "location");
		var notesChangeHandler = this.props.changeHandler(this.props.edition.edition, "notes");
		let self = this
		function locationChange(event) {self.setState({locationChanged:true});locationChangeHandler(event);};
		function notesChange(event) {self.setState({notesChanged:true});notesChangeHandler(event);};
		return (
			<div className="columns">
				<div className="column is-1"><a href={'/image/' + this.props.edition.imageID + '/edition/' + this.props.edition.edition}>{this.props.edition.edition}</a></div>
				<div className="column is-1">{this.props.edition.status}</div>
				<div className="column is-2">{this.props.edition.who}</div>
				<div className="column is-8">
					<form id="editions_form" onSubmit={this.handleSubmit} > 
						<div className="field is-horizontal">
							<div className="field-body">
								<div className="field ">
									<input type="text" className={'input' + (this.state.locationChanged?' is-changed':'')}  value={this.props.edition.location} onChange={locationChange} />
								</div>
								<div className="field">
									<input type="text" className={'input' + (this.state.notesChanged?' is-changed':'')} value={this.props.edition.notes} onChange={notesChange} />
								</div>
								<div className="field">
									<button type="submit" className={'button' + ((this.state.notesChanged||this.state.locationChanged)?' is-primary':'')} >Save</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

export {EditionRow};
export default EditionRow;
