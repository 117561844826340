import React, {Component} from 'react';
import AuthService from '../services/AuthService.js';
import ImageRow from './image-row.js';
import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.min.js';
import 'bulma-calendar/dist/css/bulma-calendar.min.css';
import update from 'immutability-helper';

class EditionFilter extends Component {

	constructor(props) {
		/* State initialisation and method binding */
		super(props);
		this.state = {
			resultsLoading: true,
			results: [],
			query: {
				who:'anthea',
				location: 'Anthea',
				status: 'SOLD',
				printstatus: 'FRAMED',
			}
		};
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	};

	getSearchResults() {
		var qryString = this.buildQueryString(this.state.query);
		this.setState({resultsLoading:true});
		fetch(process.env.REACT_APP_IMAGES_BASE_URL + '/filtereditions?' + qryString, {headers: {'Authorization': 'Bearer '+AuthService.getApiKey()}})
		.then(response => response.json())
		.then(data =>
			{this.setState({results:data, resultsLoading: false })});
	}

	componentWillMount() {
		/*Fetch data from API when loading*/
		this.getSearchResults();
	}
	
	buildQueryString(query) {
		var qryArray = Object.keys(query).map((key,index) => key + '=' + query[key]);
		let qryString = qryArray.join('&');
		return qryString;
	}

	handleSearch(event) {
		event.preventDefault();
		this.getSearchResults();
	}

	handleSearchChange(event) {
		var newQuery = update(this.state.query, {[event.target.name]: {$set: event.target.value}})
		this.setState({query: newQuery});
	}

	render() {
		if (this.state.resultsLoading) {
			return (
				<div>
				LOADING
				</div>
			)
		} else {
			return (
				<div>
					<form id="editionSearchForm" className="field is-horizontal" onSubmit={this.handleSearch}> 
						<div className="field-body">
							<div className="field">
								<div className="control">
									<input class="input" 
										type="text" 
										placeholder="who" 
										name="who" 
										onChange = {this.handleSearchChange}
										value={this.state.query.who}/>
								</div>
							</div>
							<div className="field">
								<div className="control">
									<input class="input"
										type="text"
										placeholder="location"
										name="location"
										onChange = {this.handleSearchChange}
										value={this.state.query.location}/>
								</div>
							</div>
							<div className="field">
								<div className="control">
									<div className="select">
										<select name="status"
											onChange = {this.handleSearchChange}
											value={this.state.query.status}>
											<option></option>
											<option>FREE</option>
											<option>RESERVED</option>
											<option>SOLD</option>
										</select>
									</div> 
								</div>
							</div>
							<div className="field">
								<div className="control">
									<div className="select">
										<select name="printstatus"
											onChange = {this.handleSearchChange}
											value={this.state.query.printstatus}>
											<option></option>
											<option>UNPRINTED</option>
											<option>UNFRAMED</option>
											<option>FRAMED</option>
										</select>
									</div> 
								</div>
							</div>
							<div className="field">
								<div className="control">
									<button className="button is-primary">
										Search
									</button>
								</div>
							</div>
						</div>
					</form>
					<table>
					<tr>
						<th></th>
						<th>Who</th>
						<th>Title</th>
						<th>Edition</th>
						<th>Location</th>
						<th>Notes</th>
						<th>Status</th>
						<th>Print Status</th>
						<th>Paid</th>
					</tr>
					{this.state.results.map((result, i)=> <EditionFilterRow result={result} key={result.id} />)}
					</table>
				</div>
			);
		}
	}
}

class EditionFilterRow extends Component {
	constructor(props) {
		super(props);
		this.result = props.result;
		this.imageURL = process.env.PUBLIC_URL + '/' + this.result.image.thumbfile;
	}

	render() {
		var edition = this.result;
		return (
		<tr>
			<td style={{width:100}}>
				<a href={"/image/" + edition.imageID + "/edition/" + edition.edition}>
					<img src={this.imageURL} />
			  </a>
			</td>
			<td>{edition.who}</td>
			<td>{edition.image.title}</td>
			<td>{edition.edition}</td>
			<td>{edition.location}</td>
			<td>{edition.notes}</td>
			<td>{edition.status}</td>
			<td>{edition.printstatus}</td>
			<td>{(edition.gallerypaid=='1'?'PAID':'UNPAID')}</td>
		</tr>
		);
	}
}

export default EditionFilter;
