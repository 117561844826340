import React, { Component } from 'react';
import {EditionRow} from './edition-row.js';
import ImageRow from './image-row.js';
import AuthService from '../services/AuthService.js';
import handleErrors from '../handleErrors.js';

class ImageDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			image: null,
			errors: [],
		};
		this.handleEditionFieldChange = this.handleEditionFieldChange.bind(this);
	}

	componentWillMount() {
		const imageID = this.props.match.params.id;
		fetch(process.env.REACT_APP_IMAGES_BASE_URL+'/image/'+imageID, {headers: {'Authorization': 'Bearer '+AuthService.getApiKey()}})
			.then(handleErrors)
			.then(response => response.json())
			.then(data =>
				{this.setState({image:data, isLoading: false})}
			)
			.catch(err => {
				this.setState({
					errors: [err.message],
					isLoading: false
				});
				console.log(err.message);
			});
	}
	handleEditionFieldChange(editionToChange, fieldName) {
		var imageDetail = this;
		return function(event) {
			var image = Object.assign({}, imageDetail.state.image);
			image.editions.forEach(function(edition) {
				if (edition.edition === editionToChange)
				{
					edition[fieldName] = event.target.value;
				}
			});
			imageDetail.setState({image: image});
		}
	}

	render() {
		if (this.state.isLoading) {
			return (
				<div>
				LOADING
				</div>
			)
		}
		else {
			if (this.state.errors.length > 0)
			{
				return (
				<div className="notification is-warning">
					{JSON.stringify(this.state.errors)}
					</div>
				);
			}
			else
			{
				return (
					<div className="imageDetail">
					<ImageRow image={this.state.image} />
					<div className="box" id="editions">
					<div className="columns">
					<div className="column is-1">Edition</div>
					<div className="column is-1">Status</div>
					<div className="column is-2">Who</div>
					<div className="column is-3">Location</div>
					<div className="column is-3">Notes</div>
					</div>
					{this.state.image.editions.map(edition => <EditionRow edition={edition} key={edition.edition} changeHandler={this.handleEditionFieldChange} />)}
					</div>
					</div>);
			}
		}
	}
}

export default ImageDetail
