import React from 'react';
import {withRouter} from 'react-router';

import AuthService from '../services/AuthService.js'
const AuthButton = withRouter(({ history }) => (
  AuthService.IsLoggedIn() ? (
			<React.Fragment>
  	  <div className="navbar-item"> 
				Welcome {AuthService.getUserName()}! 
			</div> 
			<a href="/logout" className="navbar-item" onClick={(e) => {
        e.preventDefault();AuthService.Logout(() => history.push('/'))
      }}>
				Sign out
			</a>
			</React.Fragment>
  ) : (
    <p>Please log in below</p>
  )
))

export default AuthButton;
