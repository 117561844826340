// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import AuthService from '../services/AuthService'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, roles, ...rest }) => {

	// Add your own authentication on the below line.
	const isLoggedIn = AuthService.IsLoggedIn();
	const currentUser = AuthService.getCurrentUser();
	if (isLoggedIn)
	{
		// check if route is restricted by role
		if (roles)
		{
			roles = roles.map(function(value) {
				return value.toLowerCase();
			});
			var userRole = currentUser.role.toLowerCase();
		}
		if (roles && roles.indexOf(userRole) === -1) {
			// role not authorised so redirect to home page
			return (
						<Route {...rest} render = {props => ( <Redirect to={{ pathname: '/'}} />)} />
			);
			}
			else {

				return (
						<Route {...rest} render = {props => ( <Component {...props} />) } />
					);
					}
			}
			else
			{
				return (
						<Route {...rest} render = {props => ( <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)} />
					);
					}
			}

			export default PrivateRoute
