import React, { Component } from 'react';
import EditionBox from './edition-box.js';

class ImageRow extends Component {
	render() {
		var imgUrl=process.env.PUBLIC_URL+'/'+this.props.image.fullfile;
		var editionsBlock  
		if (this.props.showEditions) {
			editionsBlock = 
				(<div className="columns is-multiline">
			{this.props.image.editions.map(edition => <EditionBox key={this.props.image.id + '_' + edition.edition} imageID={this.props.image.id} edition = {edition} showDialogCB = {this.props.showDialogCB} hideDialogCB = {this.props.hideDialogCB} />)}
				</div>);
		}
		return (
			<article className="media">
				<figure className="media-left">
					<p className="image is-128x128">
						<img alt={this.props.image.title} src={imgUrl} />
					</p>
				</figure>
				<div className="media-content">
					<div className="content is-small">
						<h1 className="title">
							<a href={'/image/'+ this.props.image.id}>{this.props.image.title}</a>
						</h1>
						<div><a href={'/?series='+this.props.image.series}>{this.props.image.series}</a></div>
						<div>{this.props.image.medium}</div>
						<div>${this.props.image.framedprice}</div>
						<div>{this.props.image.size}</div>
					</div>
		{editionsBlock}
				</div>
			</article>
		);
	}
}

export default ImageRow;
