import React, {Component} from 'react';
import AuthService from '../services/AuthService.js';
import ImageRow from './image-row.js';
import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.min.js';
import 'bulma-calendar/dist/css/bulma-calendar.min.css';
import update from 'immutability-helper';

class EditionDetail extends Component {

	constructor(props) {
		/*State initialisation and method binding*/
		super(props);
		this.state = {
			imageLoading: true,
			editionLoading: true,
			image: null,
			edition: null,
			modified: {},
		};
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.updateEdition = this.updateEdition.bind(this);
	};

	initiateCalendars() {
		// Initialize all input of date type.
		const calendars = bulmaCalendar.attach('[type="date"]', {displayMode:'dialog'});
		// Loop on each calendar initialized
		calendars.forEach(calendar => {
			// Add listener to date:selected event
			calendar.on('date:selected', date => {
				console.log(date);
			});
		});
	}

	handleFieldChange(e) {
	var newEdition = update(this.state.edition, {[e.target.name]: {$set: e.target.value}});
		this.setState({edition: newEdition});
		this.setState({modified: update(this.state.modified, {[e.target.name]: {$set: true}})});
	}

	updateEdition(e) {
		/*Update the edition based on current attribute values*/
		var edition = this.state.edition;
    var url = process.env.REACT_APP_IMAGES_BASE_URL + '/image/' + edition.imageID + '/edition/' + edition.edition;
    var params = {method: 'PATCH',
      headers: { 'Content-Type': 'application/json',
      'Authorization': 'Bearer '+AuthService.getApiKey()},
      body: JSON.stringify(edition)
    };
		fetch(url, params).then(res => {
			for (const k of Object.keys(this.state.modified))
			{
				/*Clear modification flag on each field*/
				this.setState({ modified: update(this.state.modified, {[k]: {$set: false}})});
			}
			return res;
		}).catch(err => {console.log(err)});
	}

	componentWillMount() {
		/*Fetch data from API when loading*/
		const imageID = this.props.match.params.imageID;
		const editionId = this.props.match.params.editionId;
		fetch(process.env.REACT_APP_IMAGES_BASE_URL + '/image/'+imageID, {headers: {'Authorization': 'Bearer '+AuthService.getApiKey()}})
		.then(response => response.json())
		.then(data =>
			{this.setState({image:data, imageLoading:false })}
		)
		fetch(process.env.REACT_APP_IMAGES_BASE_URL + '/image/'+imageID+'/edition/'+editionId, {headers: {'Authorization': 'Bearer '+AuthService.getApiKey()}})
		.then(response => response.json())
		.then(data =>
			{
				for (const k of Object.keys(data))
				{
					this.setState({ modified: update(this.state.modified, {[k]: {$set: false}})});
				}
				this.setState({edition:data, editionLoading: false})}
		)
	}

	componentDidUpdate()
	{
		/*Initiate calendars after the component is updated*/
		setTimeout(this.initiateCalendars());
	}

	render() {
		/*Primary render method*/
		if (this.state.imageLoading || this.state.editionLoading) {
			return (
				<div>
				LOADING
				</div>
			)
		} else {
			return (
				<div className="editionDetail">
					<ImageRow image={this.state.image} />
					<div className="box" id="edition">

						{/*WHO*/}
						<div className="field">
							<label className="label">Who</label>
							<div className="control">
								<input className={"input" + (this.state.modified.who?' is-changed':'')}
									type="text"
									value={this.state.edition.who}
									onChange = {this.handleFieldChange} name="who"/>
							</div>
							<p className="help">Help text</p>
						{/*who*/}
						</div>

						{/*STATUS*/}
						<div className="field is-horizontal">
							<label className="label">Status</label>
							<div className="control">
								<div className="select">
									<select value={this.state.edition.status}
									  onChange = {this.handleFieldChange} name="status">
										<option>FREE</option>
										<option>RESERVED</option>
										<option>SOLD</option>
									</select>
								</div>
							</div>
							<p className="help">Is this edition sold?</p>


						{/*PRINTSTATUS*/}
							<div className="control">
								<div className="select">
									<select value={this.state.edition.printstatus}
									  onChange = {this.handleFieldChange} name="printstatus">
										<option>UNPRINTED</option>
										<option>UNFRAMED</option>
										<option>FRAMED</option>
									</select>
								</div>
							</div>
							<p className="help">Is this edition printed?</p>
						</div> {/*printstatus*/}

						{/*NOTES*/}
						<div className="field">
							<label className="label">Notes</label>
							<div className="control">
								<textarea className={"textarea" + (this.state.modified.notes?' is-changed':'')}
									value={this.state.edition.notes}
									onChange = {this.handleFieldChange} name="notes"/>
							</div>
							<p className="help">Help text</p>
						</div> {/*notes*/}

						{/*LOCATION*/}
						<div className="field">
							<label className="label">Location</label>
							<div className="control">
								<input className={"input" + (this.state.modified.location?' is-changed':'')}
									type="text"
									value={this.state.edition.location}
									onChange = {this.handleFieldChange} name="location"/>
							</div>
							<p className="help">Help text</p>
						</div> {/*location*/}

						{/*CLIENT*/}
						<div className="field">
							<label className="label">Client</label>
							<div className="control">
								<input className={"input" + (this.state.modified.client?' is-changed':'')}
									type="text"
									value={this.state.edition.client}
									onChange={this.handleFieldChange} name="client"/>
							</div>
							<p className="help">Help text</p>
						</div> {/*client*/}

					{/*DATES*/}
						<div className="field is-horizontal">
							<div className="field-label">
								<label className="label">Dates</label>
							</div>
							<div className="field-body">

								{/*PRINTDATE*/}
								<div className="field">
									<div className="control">
										<input id="my-element" className={"input" + (this.state.modified.printdate?' is-changed':'')}
											type="date"
											value={this.state.edition.printdate}
											onChange = {this.handleFieldChange} name="printdate"/>
									</div>
									<p className="help">Printed Date</p>
								</div> {/*printdate*/}

								{/*PAID DATE*/}
								<div className="field">
									<div className="control">
										<input id="my-element" className={"input" + (this.state.modified.paiddate?' is-changed':'')}
											type="date"
											value={this.state.edition.paiddate}
											onChange = {this.handleFieldChange} name="paiddate"/>
									</div>
									<p className="help">Paid Date</p>
									{/*paid date*/}
								</div>
							</div>
						</div> {/*dates*/}

								{/*ORDERED*/}
								<div className="field is-horizontal">
							<label className="label">Ordered</label>
									<div className="control">
										<div className="select">
											<select value={this.state.edition.ordered}
												onChange = {this.handleFieldChange} name="ordered">
												<option value="YES" >YES</option>
												<option value="NO">NO</option>
											</select>
										</div>
									</div>
							<label className="label">Delivered</label>
									<div className="control">
										<div className="select">
											<select value={this.state.edition.delivered}
												onChange = {this.handleFieldChange} name="delivered">
												<option value="YES" >YES</option>
												<option value="NO">NO</option>
											</select>
										</div>
									</div>
							<label className="label">Gallery Paid</label>
									<div className="control">
										<div className="select">
											<select value={this.state.edition.gallerypaid}
												onChange = {this.handleFieldChange} name="gallerypaid">
												<option value="1" >1</option>
												<option value="0">0</option>
											</select>
										</div>
									</div>
								</div> {/*gallerypaid*/}

						<div className="control">
							<button className="button" onClick={this.updateEdition}>Save</button>
						</div>

					</div> {/*box edition*/}
				</div>

			);
		}
	}
}

export default EditionDetail;
