import React, { Component } from 'react';
import { Route, Switch} from "react-router-dom";
import 'bulma/css/bulma.css';

import AuthService from './services/AuthService.js';
import handleErrors from './handleErrors.js';
import './App.css';
import {AllImages, ImageDetail, EditionDetail} from './components/image-components.js';
import EditionFilter from './components/edition-filter.js';
import PrivateRoute from './components/PrivateRoute.js';
import Login from './components/Login.js';
import AuthButton from './components/AuthButton.js';

class Header extends Component
{
	constructor(props) {
		super(props);
		this.state = {
			isLoading:true,
			series: [],
		};
	}
	
	componentWillMount() {
		var url = process.env.REACT_APP_IMAGES_BASE_URL + '/series';
		var params = {headers: {'Authorization': 'Bearer '+AuthService.getApiKey()}};
		fetch(url, params)
		.then(handleErrors)
		.then(response => response.json())
		.then(data =>
			{this.setState({series:data.map(series => series.name), isLoading:false})}
		)
		.catch(err => {
			console.log(err.message);
		});
	}
	render() {
			return (
				<header >
					<nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
						<div className="navbar-brand">
							<a className="navbar-item" href="/">
								<img alt="" src="https://bulma.io/images/bulma-logo.png" width="112" height="28" />
							</a>
						</div>

						<div id="main-navbar" className="navbar-menu">
							<div className="navbar-start">
								<a href="/" className="navbar-item">
									Images
								</a>
								<div className="navbar-item has-dropdown is-hoverable">
									<a href="/" className="navbar-link">
										Series
									</a>

				{(!this.state.isLoading) && (<div className="navbar-dropdown">
										{this.state.series.map(series => (
											<React.Fragment key={series}>
										<a href={"/?series=" + series} className="navbar-item">
											{series}
										</a>
											</React.Fragment>
										))}
									</div>)}
								</div>
								<div className="is-size-7">{process.env.REACT_APP_IMAGES_BASE_URL}</div>
							</div>
							<div className="navbar-end">
								<div className="navbar-item">
									<div className="buttons">
										<AuthButton/>
									</div>
								</div>
							</div>
						</div>
					</nav>
				</header>
			);
	}
}
class Main extends Component {
	render() {
		return (
			<main>
			<div className="content">
			<div className="container">
			<section className="section">
			<Switch>
			<PrivateRoute exact path='/' component= {AllImages} />
			<Route exact path='/login' component= {Login}/>
			<PrivateRoute exact path='/image/:id' component = {ImageDetail} roles={['admin']}/>
			<PrivateRoute exact path='/image/:imageID/edition/:editionId' component = {EditionDetail} roles={['admin']} />
			<PrivateRoute exact path='/filterimage' component = {EditionFilter} roles={['admin']} />
			</Switch>
			</section>
			</div>
			</div>
			</main>
		);
	}
}
function App() {
	return (
		<div>
		<Header />
		<Main />
		</div>
	);
}
export default App;
